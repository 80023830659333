import type { JSX } from 'react';
import styles from '../ContentRowTemplateItem.css';

type RankItemStandardProps = {
  rank: number;
};

export function RankItemStandard({ rank }: RankItemStandardProps): JSX.Element {
  return (
    <div className={styles.rankItemStandard}>
      <div aria-hidden>{rank}</div>
    </div>
  );
}
