import { Media } from '@dce-front/dive';
import type { Ratio } from '@dce-front/onewebapp-utils';
import type { CSSProperties, JSX } from 'react';
import styles from './ContentRowTemplateItem.css';

type ContentRowTemplateItemPlaceholderProps = {
  ratio: Ratio;
  style?: CSSProperties;
};

export function ContentRowTemplateItemPlaceholder({
  ratio,
  style,
}: ContentRowTemplateItemPlaceholderProps): JSX.Element {
  return (
    <li className={styles.contentRowTemplateItem} style={style}>
      <Media aspectRatio={ratio} data-tv-focusable />
    </li>
  );
}
